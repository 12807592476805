import { render, staticRenderFns } from "./OptimalWill.vue?vue&type=template&id=5c411e35&scoped=true&"
import script from "./OptimalWill.vue?vue&type=script&lang=js&"
export * from "./OptimalWill.vue?vue&type=script&lang=js&"
import style0 from "./OptimalWill.vue?vue&type=style&index=0&id=5c411e35&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c411e35",
  null
  
)

export default component.exports