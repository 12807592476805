<template>
  <div>
    <div class="searchbox">
      <el-form inline size="mini">
        <el-form-item label="酒店名称" class="marginright20">
          <el-input
            @input="searchFn"
            size="mini"
            v-model="searchData.key"
            class="width140"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态" class="marginright20">
          <el-select
            size="mini"
            @change="searchFn"
            v-model="searchData.state"
            class="width100"
          >
            <el-option
              v-for="item in optionsList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="searchFn"
            >查询</el-button
          >
          <el-button type="primary" plain size="mini" @click="clearFn"
            >清空</el-button
          >
          <el-button type="success" size="mini" @click="toAdd"
            >加入优享会</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="mainbox">
      <div class="miantitleflexbox">
        <div>
          <el-button type="primary" size="mini" @click="openchangeStateDia"
            >批量报名/停用</el-button
          >
        </div>
        <div>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="searchData.page"
            :page-size="searchData.rows"
            :page-sizes="[15, 30, 45, 60]"
            layout="total, sizes, prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <div>
        <el-table
          :data="tableList"
          style="width: 100%; font-size: 12px"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column label="酒店名称" prop="hotelName"> </el-table-column>
          <el-table-column label="酒店ID" prop="hotelSonId"> </el-table-column>
          <el-table-column label="促销折扣" width="230" align="center">
            <template slot-scope="scope">
              <el-popover placement="bottom" trigger="hover">
                <div class="costValuelabelboxtwo">
                  <div v-if="scope.row.zcostValue">
                    钻石及以上：{{ scope.row.zcostValue | formatCostTwo }}
                  </div>
                  <div v-if="scope.row.bcostValue">
                    铂金贵宾：{{ scope.row.bcostValue | formatCostTwo }}
                  </div>
                  <div v-if="scope.row.hcostValue">
                    黄金贵宾：{{ scope.row.hcostValue | formatCostTwo }}
                  </div>
                  <div v-if="scope.row.costValue">
                    白银贵宾：{{ scope.row.costValue | formatCostTwo }}
                  </div>
                </div>
                <!-- <el-button slot="reference">click 激活</el-button> -->
                <div slot="reference" class="costValuelabelbox">
                  <span v-if="scope.row.zcostValue"
                    >{{ scope.row.zcostValue | formatCostOne }}/</span
                  >
                  <span v-if="scope.row.bcostValue"
                    >{{ scope.row.bcostValue | formatCostOne }}/</span
                  >
                  <span v-if="scope.row.hcostValue"
                    >{{ scope.row.hcostValue | formatCostOne }}/</span
                  >
                  <span v-if="scope.row.costValue">{{
                    scope.row.costValue | formatCostOne
                  }}</span>
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="生效日期" prop="createTime" align="center">
          </el-table-column>
          <el-table-column label="状态" prop="state" align="center" width="150">
            <template slot-scope="scope">
              <el-tag size="mini" type="success" v-if="scope.row.state == 1"
                >已加入</el-tag
              >
              <el-tag size="mini" type="danger" v-else-if="scope.row.state == 0"
                >已退出</el-tag
              >
              <el-tag size="mini" type="info" v-else>未知</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="toEditPage(scope.row)"
                >修改</el-button
              >
              <el-button
                type="text"
                size="mini"
                v-if="scope.row.state == 0"
                @click="stopPromotionMemberUp(scope.row)"
                >加入</el-button
              >
              <el-button
                v-if="scope.row.state == 1"
                type="text"
                class="redcolor"
                size="mini"
                @click="stopPromotionMemberUp(scope.row)"
                >退出</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog
      title="批量加入/退出"
      width="400px"
      :visible.sync="stateDiaVisible"
    >
      <div>
        <div style="text-align: center">
          批量操作
          <el-radio v-model="stateRadio" label="1" size="mini" border
            >加入</el-radio
          >
          <el-radio v-model="stateRadio" label="0" size="mini" border
            >退出</el-radio
          >
        </div>

        <div class="diafooterbtn">
          <el-button
            @click="closeFn"
            style="width: 84px; margin-right: 10%"
            size="small"
            type="primary"
            plain
            >取消</el-button
          >
          <el-button
            style="width: 84px"
            @click="handStateData"
            size="small"
            type="primary"
            >保存</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getMemberList_api,
  stopPromotionMemberUp_api,
} from "../../apis/hotelPackage";
export default {
  data() {
    return {
      searchData: {
        rows: 15,
        page: 1,
        key: "",
        state: "",
      },
      optionsList: [
        { label: "全部", value: "" },
        { label: "已退出", value: "0" },
        { label: "已加入", value: "1" },
      ],
      checkedList: [],
      stateDiaVisible: false,
      checkedIds: "",
      total: 0,
      tableList: [],
      stateRadio: "1",
    };
  },
  created() {
    this.searchFn();
  },
  filters: {
    formatCostOne(val) {
      if (val == "0.5") {
        return "50%OFF";
      } else if (val == "0.55") {
        return "45%OFF";
      } else if (val == "0.6") {
        return "40%OFF";
      } else if (val == "0.65") {
        return "35%OFF";
      } else if (val == "0.7") {
        return "30%OFF";
      } else if (val == "0.75") {
        return "25%OFF";
      } else if (val == "0.8") {
        return "20%OFF";
      } else if (val == "0.85") {
        return "15%OFF";
      } else if (val == "0.9") {
        return "10%OFF";
      } else if (val == "0.95") {
        return "5%OFF";
      }
    },
    formatCostTwo(val) {
      if (val == "0.5") {
        return "5折(50%OFF)";
      } else if (val == "0.55") {
        return "5.5折(45%OFF)";
      } else if (val == "0.6") {
        return "6折(40%OFF)";
      } else if (val == "0.65") {
        return "6.5折(35%OFF)";
      } else if (val == "0.7") {
        return "7折(30%OFF)";
      } else if (val == "0.75") {
        return "7.5折(25%OFF)";
      } else if (val == "0.8") {
        return "8折(20%OFF)";
      } else if (val == "0.85") {
        return "8.5折(15%OFF)";
      } else if (val == "0.9") {
        return "9折(10%OFF)";
      } else if (val == "0.95") {
        return "9.5折(5%OFF)";
      }
    },
  },
  methods: {
    searchFn() {
      this.searchData.page = 1;
      this.getMemberList();
    },
    clearFn() {
      this.searchData.key = "";

      this.searchData.state = "";
    },
    async getMemberList() {
      let { code, data } = await getMemberList_api(this.searchData);
      if (code == 0) {
        this.tableList = data.records;
        this.total = data.total;
      }
    },
    //分页-每页条数
    handleSizeChange(val) {
      this.searchData.rows = val;
      this.searchFn();
    },
    //分页-跳转页数
    handleCurrentChange(val) {
      this.searchData.page = val;
      this.getMemberList();
    },
    //列表选择
    handleSelectionChange(val) {
      this.checkedList = val;
    },
    //打开批量模态框
    openchangeStateDia() {
      if (this.checkedList.length == 0) {
        this.$message.error("请勾选至少一个促销");
      } else {
        // this.checkedIds = this.checkedList.join(",");
        let idsArr = [];
        this.checkedList.forEach((item) => {
          idsArr.push(item.id);
        });
        this.checkedIds = idsArr.join(",");
        this.stateDiaVisible = true;
      }
    },
    async handStateData() {
      let { code, data } = await stopPromotionMemberUp_api({
        pids: this.checkedIds,
        state: this.stateRadio,
      });
      if (code == 0) {
        this.$message.success("操作成功");
        this.stateDiaVisible = false;
        this.getMemberList();
      }
    },
    closeFn() {
      this.stateDiaVisible = false;
    },
    stopPromotionMemberUp(row) {
      let stateLabel;
      if (row.state == 0) {
        stateLabel = "加入";
      } else if (row.state == 1) {
        stateLabel = "退出";
      }
      this.$confirm(`确定${stateLabel}此促销？`, "提示", {
        type: "warning",
      })
        .then(() => {
          this.stopPromotionMemberUpFn(row);
        })
        .catch(() => {});
    },

    async stopPromotionMemberUpFn(row) {
      let upState;
      if (row.state == 0) {
        upState = "1";
      } else if (row.state == 1) {
        upState = "0";
      }
      let { code, data } = await stopPromotionMemberUp_api({
        pids: row.id,
        state: upState,
      });
      if (code == 0) {
        this.$message.success("操作成功");
        this.getMemberList();
      }
    },

    toEditPage(row) {
      this.$router.push({
        //path:'/addTravelPromote'
        path: "/addOptimalWillPage",
        query: {
          pid: row.id,
        },
      });
    },

    toAdd() {
      this.$router.push({
        path: "/addOptimalWillPage",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.searchbox {
  width: 1160px;
  padding: 20px 20px 2px 20px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
  border-radius: 10px;
}
.width100 {
  width: 100px;
}
.width140 {
  width: 140px;
}
.width230 {
  width: 230px;
}
.marginright20 {
  margin-right: 20px;
}
.mainbox {
  width: 100%;
  margin-top: 20px;
  padding: 20px 0px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
  border-radius: 10px;
}
.miantitleflexbox {
  width: 1180px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.costValuelabelbox {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #256def;
  cursor: pointer;
}
.costValuelabelboxtwo {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}
.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}
</style>

